<template>
  <m-container>
    <page-title
      :page-title="!$language ? 'Edit Exam' : 'পরীক্ষা এডিট করুন'"
      class="mt-5"
    >
    <template #left>
       <v-btn
            class="mr-2"
            color="black"
            icon
            large
            @click="$router.replace({name: 'exams'})"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
    </template>
    </page-title>

    <lazy-load :item="!loadingExam">
      <exam-create-form
        :course-id="courseId"
        :exam="sourceExam"
        @cancel="cancel"
        @done="onDone"
      ></exam-create-form>
    </lazy-load>
  </m-container>
</template>

<script>
import ExamCreateForm from "#ecf/exam/components/ExamCreateForm";
import { ExamProvider } from "#ecf/exam/mixins";
import { Logger } from "/global/utils/helpers";

export default {
  name: "ExamEdit",
  components: {
    ExamCreateForm
  },
  props: {
    examId: {
      type: String,
      required: true
    },
    courseId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    saving: false,
    sourceExam: {},
    loadingExam: false
  }),
  inject: ["$courseOwnerId", "$courseId"],
  computed: {},
  async created() {
    try {
      this.loadingExam = true;
      this.sourceExam = await this.$store.dispatch("exam/get", {
        id: this.examId,
        course_id: this.$courseId(),
        owner_id: this.$courseOwnerId()
      });
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message]);
    } finally {
      this.loadingExam = false;
    }
  },
  methods: {
    async onDone() {
      await this.$router.replace({ name: "exams" });
    },
    async cancel() {
      this.$router.back();
    }
  }
};
</script>

<style></style>
