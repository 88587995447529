var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "page-title": !_vm.$language ? "Edit Exam" : "পরীক্ষা এডিট করুন",
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "black", icon: "", large: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.replace({ name: "exams" })
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("arrow_back")])],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "lazy-load",
        { attrs: { item: !_vm.loadingExam } },
        [
          _c("exam-create-form", {
            attrs: { "course-id": _vm.courseId, exam: _vm.sourceExam },
            on: { cancel: _vm.cancel, done: _vm.onDone },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }